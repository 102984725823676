import React from "react";

export default function Footer() {
  return (
    <footer className="bg-primary p-2 text-center ">
      <a className="text-white" href="https://vladis.lt" target="_blank">
        vladis.lt
      </a>
    </footer>
  );
}
